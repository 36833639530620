import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const CookiesModal = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      {children}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieThriftys"
        style={{ background: "#F0F4E0", color: "#604331", fontSize: 20 }}
        buttonStyle={{
          color: "white",
          fontSize: "23px",
          background: "#604331",
        }}
        expires={365}
        overlay
      >
        {t("cookies.title")}
        <div style={{ fontSize: "12px" }}>
          {t("cookies.text")}{" "}
          <a
            style={{ color: "#604331", textDecoration: "underline" }}
            href={process.env.PUBLIC_URL + "/privacy-policy"}
          >
            {t("cookies.privacy")}
          </a>
          .
        </div>
      </CookieConsent>
    </>
  );
};

export default CookiesModal;
